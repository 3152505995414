<template>
  <div>
    <base-section
      id="serviceproviderparameters-account"
      space="16"
    >
      <v-container>
        <kdw-Statusbar-card
          v-model="snackbarUpdate"
          :snackbar-timeout="snackbarTimeout"
          :snackbar-message="snackbarMessage"
        />
        <!-- <v-snackbar
          v-model="snackbarUpdate"
          :timeout="snackbarTimeout"
          color="blue-grey"
          rounded="pill"
          top
        >
          {{ snackbarMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="snackbarUpdate = false"
            >
              {{ $t('button.close') }}
            </v-btn>
          </template>
        </v-snackbar>
        -->
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="12"
          >
            <base-info-card :title="$t('paramSP.mainTitle')" />
          </v-col>
        </v-row>
        <v-row>
          <v-tabs vertical>
            <v-tab>
              <v-icon left>
                mdi-map-marker-radius
              </v-icon>
              {{ $t('paramSP.searchRadiusOption') }}
            </v-tab>
            <!-- Removed as Free
            <v-tab>
              <v-icon left>
                mdi-archive-outline
              </v-icon>
              {{ $t('paramSP.currentSubscriptionOption') }}
            </v-tab>
            -->
            <!-- Remove as Free
            <v-tab>
              <v-icon left>
                mdi-currency-eur
              </v-icon>
              {{ $t('paramSP.walletOption') }}
            </v-tab>
            -->
            <v-tab>
              <v-icon left>
                mdi-comment-flash-outline
              </v-icon>
              {{ $t('paramSP.legalOption') }}
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-web
              </v-icon>
              {{ $t('paramSP.language') }}
            </v-tab>
            <!---
            <v-tab>
              <v-icon left>
                mdi-lock
              </v-icon>
              Option 2
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-access-point
              </v-icon>
              Option 3
            </v-tab>
            -->
            <!--- SEARCH OPTION DETAILS - RADIUS ------------------------------------------------->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>
                    {{ $t('paramSP.searchRadiusOption_line1') }}
                  </p>
                  <!---
                  <v-slider
                    v-model="radius"
                    :max="7"
                    :tick-labels="labels"
                    class="mx-4"
                    ticks
                  />
                  -->
                  <!-- we need to check how to use the : thumb-label option   and change the color -->
                  <v-text-field
                    v-model="parameters.searchRadius.radius"
                    type="number"
                    hint="KM"
                  />
                  <p class="mb-0">
                    {{ $t('paramSP.searchRadiusOption_line2') }}
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <kdw-btn
                    @click="updateParameters"
                  >
                    {{ $t('button.validateQuestion') }}
                  </kdw-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <!--- CURRENT SUBSCRIPTION OPTION DETAILS ------------------------------------------------->
            <!-- Removed as free
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-radio-group
                    v-model="parameters.subscription.type"
                  >
                    <template #label>
                      <div><strong>{{ $t('paramSP.planBased') }}</strong></div>
                    </template>
                    <v-radio
                      key="1"
                      :label="$t('paramSP.kidiwiBasic')"
                      value="KidiwiBasic"
                    />
                  </v-radio-group>
                  <v-radio-group
                    v-model="newSubscription"
                    :disabled="isParamMonthBasedDisabled()"
                    @change="selectSubscriptionMethod()"
                  >
                    <template #label>
                      <div><strong>{{ $t('paramSP.textYearMonthBased') }}</strong></div>
                    </template>
                    <v-radio
                      key="11"
                      :label="$t('paramSP.monthBased') + ' (' + monthlyCost +'€ HT)'"
                      value="monthly"
                    />
                    <v-radio
                      key="12"
                      :label="$t('paramSP.yearBased') + ' (' + yearlyCost +'€ HT)'"
                      value="yearly"
                    />
                  </v-radio-group>
                  <v-card
                    v-if="parameters.subscription.method === 'yearly'"
                  >
                    {{ $t('paramSP.subscriptionDate') }} {{ shortSubcriptionDate() }}
                  </v-card>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <kdw-btn
                    :disabled="isParamMonthBasedDisabled()"
                    @click="updateParameters"
                  >
                    {{ $t('button.validateQuestion') }}
                  </kdw-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            -->
            <!--- WALLET OPTION DETAILS ------------------------------------------------->
            <!-- Removed as free
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>{{ $t('paramSP.walletOption_line1') }}</p>
                  <v-text-field
                    v-model="parameters.wallet.nextMonthValue"
                    type="number"
                    :hint="$t('paramSP.minWalletValue')"
                    @change="nextMonthValueChanged()"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <kdw-btn
                    @click="updateParameters"
                  >
                    {{ $t('button.validateQuestion') }}
                  </kdw-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            -->
            <!--- LEGAL  OPTION DETAILS ------------------------------------------------->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>{{ $t('paramSP.legalOption_line1') }}</p>
                  <v-textarea
                    v-model="parameters.legal.default"
                    auto-grow
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <kdw-btn
                    @click="updateParameters"
                  >
                    {{ $t('button.validateQuestion') }}
                  </kdw-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <!--- Language option  ------------------------------------------------->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>{{ $t('paramSP.language_line1') }}</p>
                  <v-radio-group
                    v-model="parameters.language.default"
                  >
                    <v-radio
                      key="1"
                      :label="$t('paramSP.english')"
                      value="en"
                    />

                    <v-radio
                      key="2"
                      :label="$t('paramSP.french')"
                      value="fr"
                    />
                  </v-radio-group>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <kdw-btn
                    @click="updateParameters"
                  >
                    {{ $t('button.validateQuestion') }}
                  </kdw-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>

            <!---
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>
                    Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam libero, non adipiscing dolor urna a orci. Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Nunc sed turpis.
                  </p>

                  <p class="mb-0">
                    Donec venenatis vulputate lorem. Aenean viverra rhoncus pede. In dui magna, posuere eget, vestibulum et, tempor auctor, justo. Fusce commodo aliquam arcu. Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>
                    Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
                  </p>

                  <p class="mb-0">
                    Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nam at tortor in tellus interdum sagittis.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            -->
          </v-tabs>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator'
  import { Logger } from 'aws-amplify'
  import { loadServiceProviderParametersPure, saveServiceProviderParameters } from '@/services/Parameters'
  import { COST_BASIC_USAGE_MONTHLY_FEE_PER_MONTH, COST_BASIC_USAGE_MONTHLY_FEE_PER_YEAR, COST_BASIC_USAGE_MINIMUM } from '@/services/Cost'
  import { dateDiff, toShortDate } from '@/helpers/GenericFunctions'

  const logger = new Logger('ServiceProviderParametersAccount')

  @Component({ name: 'ServiceProviderParametersAccount' })
  class ServiceProviderParametersAccount extends Vue {
    snackbarUpdate = false
    snackbarMessage = ''
    snackbarTimeout = 2000
    yearlyCost = COST_BASIC_USAGE_MONTHLY_FEE_PER_YEAR
    monthlyCost = COST_BASIC_USAGE_MONTHLY_FEE_PER_MONTH
    parameters = { searchRadius: { radius: 10 }, subscription: { type: 'KidiwiBasic', method: 'yearly', date: '1900-01-01T00:00:00.000Z', freeperiod: 1 }, wallet: { nextMonthValue: 30 }, legal: { default: '' }, language: { default: 'fr' } }
    newSubscription = 'yearly'
    spCreatedAt = ''

    alertSnackBar (message, snackbarTimeout) {
      this.snackbarMessage = message
      this.snackbarUpdate = true
      this.snackbarTimeout = snackbarTimeout
    }

    created () {
      loadServiceProviderParametersPure()
        .then(result => {
          this.spCreatedAt = result.createdAt
          if (result !== false) {
            // console.log(result)
            if (result.parameters !== undefined) {
              const parametersLoaded = JSON.parse(result.parameters)
              this.parameters.searchRadius.radius = parametersLoaded.searchRadius.radius
              this.parameters.subscription.type = parametersLoaded.subscription.type
              this.parameters.subscription.method = parametersLoaded.subscription.method
              this.parameters.subscription.date = parametersLoaded.subscription.date
              this.newSubscription = parametersLoaded.subscription.method
              this.parameters.wallet.nextMonthValue = parametersLoaded.wallet.nextMonthValue
              this.parameters.legal.default = parametersLoaded.legal.default
              this.parameters.language.default = parametersLoaded.language.default
              // console.log(this.parameters)
            }
          }
        })
        .catch(err => logger.error(err))
    }

    updateParameters () {
      console.log('this.parameters.subscription.method:', this.parameters.subscription.method)
      console.log('this.newSubscription:', this.newSubscription)
      if ((this.parameters.subscription.method === 'monthly') && (this.newSubscription === 'yearly')) {
        // There is a subscription to a new package, yearly based
        // TO DO - re-confirmation
        if (this.spCreatedAt === '') {
          this.alertSnackBar(this.$i18n.t('parameterLib.alertFailSave'), 2000)
          return
        }
        // TO CHECK HERE
        console.log('DateDiff:', dateDiff(this.spCreatedAt.substring(0, 10)))
        // if (dateDiff(this.spCreatedAt.substring(0, 10)) < COST_BASIC_FREE_MONTH_PERIOD) {
        //   this.parameters.subscription.date = addMonths(this.spCreatedAt, COST_BASIC_FREE_MONTH_PERIOD)
        // } else {
        // it is more than trial period that the customer wants to subscribe to yearly basis
        this.parameters.subscription.date = new Date().toISOString()
        // }
        this.parameters.subscription.method = 'yearly'
      }
      if ((this.parameters.subscription.method === 'yearly') && (this.newSubscription === 'monthly')) {
        this.parameters.subscription.method = 'monthly'
      }
      saveServiceProviderParameters(this.parameters)
        .then((result) => {
          if (result === true) {
            this.alertSnackBar(this.$i18n.t('paramSP.alertUpdateOK'), 2000)
          } else {
            this.alertSnackBar(this.$i18n.t('parameterLib.alertFailSave'), 2000)
          }
        })
        .catch(err => {
          logger.error(err)
          this.alertSnackBar(this.$i18n.t('parameterLib.alertFailSave'), 2000)
        })
    }

    selectSubscriptionMethod () {
      const monthDiff = dateDiff(toShortDate(this.parameters.subscription.date))
      if ((this.newSubscription === 'monthly') && (monthDiff === 0)) {
        return
      }
      if ((this.newSubscription === 'monthly') && (monthDiff < 12)) {
        // The Subscriber is trying to change the payment method while in fact he did not finish the subscription of 1 year.
        this.newSubscription = 'yearly'
        this.alertSnackBar(this.$i18n.t('paramSP.canNotChangeSubscriptionTooEarly'), -1)
        // this.$forceUpdate()
      }
      // console.log(monthDiff)
      // console.log(this.newSubscription)
    }

    isParamMonthBasedDisabled () {
      const diff = dateDiff(toShortDate(this.parameters.subscription.date))
      if ((this.parameters.subscription.method === 'yearly') && (diff === 0)) {
        return false
      }
      if ((this.parameters.subscription.method === 'yearly') && (diff < 12)) {
        return true
      }
      return false
    }

    shortSubcriptionDate () {
      return toShortDate(this.parameters.subscription.date)
    }

    nextMonthValueChanged () {
      // console.log('nextMonthValueChanged')
      if (this.parameters.wallet.nextMonthValue < COST_BASIC_USAGE_MINIMUM) {
        this.parameters.wallet.nextMonthValue = COST_BASIC_USAGE_MINIMUM
      }
    }
  }

  export default ServiceProviderParametersAccount
</script>
